/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "swiper/css/bundle";

.grid-img-wrapper {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.pointer {cursor: pointer;}

.no-drop {cursor: no-drop;}

.primary-color {
    color: #7c3971;
}

.secondary-color {
    color:  #f18342;
}

.text-danger {
    text-align: left;
    margin-left: 15px;
    color: red
}

.error-color {
    color: red;
}

.state-Completed {
  color: red;
}

.state-Pending {
  color: orange;
}

.state-Open {
  color: green;
}

ion-searchbar.ios {
    margin-top: 5px;;
    --background: white;
}

.no-content {
    margin-left: 100px;
}

.l-fab-button {
    top:-14px;
    margin-left: -24px;
}

.r-fab-button {
    top:-14px;
    margin-right: -23px;
}

.m-fab-button {
  top:-8px;
  margin-right: -20px;
}

.vl-fab-button {
  top:-8px;
  margin-left: -16px;
}

.vr-fab-button {
  top:-8px;
  margin-right: -16px;
}

.fab-list {
    padding-top: -20px;
}

.l-img-description {
    width: 100%;
    /*height: 60px;*/
    position: absolute;
    z-index: 99;
    bottom: 0px;
    opacity: 0.6;
    background: #000;
    color: #fff;
    float: left;
    font-size: 16px;
}

.listing-text {
  width: 100%;
  bottom: 0px;
  opacity: 0.6;
  background: #000;
  color: #fff;
  float: left;
  font-size: 16px;
}

.l-overlay-text {
    float: left;
    font-size: 14px;
}

.l-img-container {
  width:100%;
  height:100%;
}

/*
View page related scss
*/

.v-image-container {
    width: 100%;
    height: 350px;
    cursor: pointer;
}

.v-social-icon{
  color: white;
}

.v-fab-button {
  top: 1px;
  margin-right: -10px;
}

.v-img-description {
  width: 100%;
  position: absolute;
  z-index: 99;
  top: 333px;
  opacity: 0.6;
  background: #000;
  color: #fff;
  float: left;
  font-size: 16px;
}

.v-overlay-text {
  float: left;
  font-size: 14px;
}

.action-sheet-left-align-buttons .action-sheet-button-inner {
    justify-content: flex-start;
    --color: #7c3971;
}

.cart-component {
    --height: 50%;
    --border-radius: 10px;
    padding: 25px;
}

.copy-toast {
  --width: 100px;
  --width: 100px;
  --border-radius: 5px;
  --background: #7c3971;
}

.cursor-pointer {
    cursor: pointer;
}

ion-item-divider{
    margin-top: 0px;
    min-height: 1px!important;
}

.rating-modal {
  --height: 60%;
  --border-radius: 10px;
  padding: 25px;
}

.video-category-modal {
  --background: #0000005c;
  &::part(content) {
  backdrop-filter: blur(12px);
  }
  ion-content {
  --background: transparent;
  }
}

.video-mylist-modal {
  --ion-background-color: #383a3e;
  --width: 100%;
}

.video-infor-modal {
  --ion-background-color: #383a3e;
}

.video-alert{
  button{
      color:#ffffff !important;
  }
}

.coming-soon-modal {
  --ion-background-color: #383a3e;
  --width: 100%;
}

.newly-discovered-modal {
  --ion-background-color: #383a3e;
  --width: 100%;
}

.infor-popover {
  --ion-background-color: #383a3e;
  --width: 50%;
}

.music-newly-discovered-modal {
  --width: 100%;
}

.music-coming-soon-modal {
  --width: 100%;
}

.music-new-hot-modal {
  --width: 100%;
}

.music-category-modal {
  --background: #0000005c;
  &::part(content) {
  backdrop-filter: blur(12px);
  }
  ion-content {
  --background: transparent;
  }
}

.shopping-cart-modal {
  --width: 100%;
}

.grid-img-wrapper {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media(min-width: 768px) {
    .mobile-header {
        display: none;
    }

    .mobile-footer {
      display: none;
    }
  }

  @media(max-width: 768px) {
    .mobile-header {
        display: block;
    }

    .header {
        display: none;
    }

    .mobile-footer {
      display: block;
    }

    .web-footer {
      display: none;
    }
  }

.approved {
    color: seagreen;
}
.pending {
    color: red;
}

.view {
  margin-left: 10px;
  color: #7c3971;
}

.s-fab-button {
  top: -10px;
  margin-right: -20px;
}

.img-100-400 {
  width: 100%;
  height: 400px;
}

.img-100-270 {
  /*width: 380px;*/
  width: 100%;
  height: 250px;
}

.img-380-270 {
  /*width: 380px;*/
  width: 100%;
  height: 270px;
}

.list-title {
  font-size: 14px;
  font-weight: bold;
}

.list-title-3 {
  font-size: 14px;
  font-weight: bold;
}

.list-title-4 {
  font-size: 16px;
  font-weight: bold;
}

/* Horizontal line */
hr.normal {
  border-top: 1px solid #666666;
}


